<!--
  后台运营账号
  + 添加账号
  + 修改表单
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="后台运营账号详情" />
    <div
      class="detail_main page_width"
      v-loading="loading">
      <div class="main_content">
        <el-form
          ref="elForm"
          :model="formData"
          :rules="rules"
          size="small"
          label-width="180px"
          label-position="left">
          <el-form-item
            label="用户名"
            prop="username">
            <el-input
              maxlength="50" show-word-limit
              style="width: 100%"
              v-model="formData.username"
              placeholder="请输入姓名"
              clearable>
            </el-input>
          </el-form-item>
          <el-form-item
            label="性别"
            prop="gender">
            <el-select
              style="width: 100%"
              v-model="formData.gender"
              placeholder="请选择性别"
              clearable>
              <el-option
                v-for="(item, index) in genderOptions"
                :key="index"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="手机号"
            prop="mobile">
            <el-input
              v-if="!id"
              maxlength="11" show-word-limit
              style="width: 100%"
              v-model="formData.mobile"
              placeholder="请输入手机号"
              clearable>
            </el-input>
            <span v-else>{{ formData.mobile }}</span>
          </el-form-item>
          <el-form-item
            label="权限"
            prop="role">
            <el-select
              v-if="!id"
              style="width: 100%"
              v-model="formData.role"
              placeholder="请选择权限"
              clearable>
              <el-option
                v-for="item in roleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled">
              </el-option>
            </el-select>
            <span v-else>{{ formData.role | formatRoleName(roleOptions) }}</span>
          </el-form-item>
          <el-form-item
            v-if="globalVar.ADMIN_ROLE_1 === formData.role"
            label="管理员地区"
            prop="province">
            <el-select
              style="width: 100%"
              v-model="formData.province"
              placeholder="请选择管理员所在地区"
              clearable>
              <el-option
                v-for="item in adminRegionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 添加管理员站好的时候会有密码 -->
          <el-form-item
            v-if="!id"
            label="密码"
            prop="password">
            <el-input
              style="width: 100%"
              v-model.trim="formData.password"
              placeholder="请输入密码"
              show-password>
            </el-input>
          </el-form-item>
          <!-- 只有管理员来修改的时候才有此表单 -->
          <el-form-item
            v-else
            label="新密码"
            prop="newPassword">
            <el-input
              style="width: 100%"
              v-model.trim="formData.newPassword"
              placeholder="请输入新密码"
              show-password>
            </el-input>
          </el-form-item>
          <el-form-item size="large">
            <!-- 查看详情 进行表单编辑 -->
            <el-row type="flex">
              <el-button
                type="primary"
                size="small"
                :loading="btnLoading"
                @click="submitForm">
                保存
              </el-button>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import globalVar from '@/configs/globalVar'
import * as api from '@/api'
import SHA256 from 'crypto-js/sha256'
export default {
  data () {
    return {
      // 页面loading
      loading: false,
      btnLoading: false,
      formData: {
        username: '',
        gender: '',
        role: '',
        mobile: '',
        password: '',
        newPassword: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入经纪人名称', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        role: [
          { required: true, message: '请选择权限', trigger: 'change' }
        ],
        province: [
          { required: true, message: '请选择管理员所在地区', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/,
            message: '密码长度为8~16位，至少需要包含数字、字母两种类型',
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/,
            message: '密码长度为8~16位，至少需要包含数字、字母两种类型',
            trigger: 'blur'
          }
        ]
      },
      // 性别选项
      genderOptions: [
        {
          label: '男',
          value: 1
        },
        {
          label: '女',
          value: 2
        }
      ]

    }
  },
  props: {
    /**
     * 账号id，
     *  + 来编辑就有Id
     *  + 来添加就无Id
     */
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    // 管理员和客服选项
    roleOptions () {
      // 权限选项
      const roleOptions = [
        {
          label: '管理员',
          value: globalVar.ADMIN_ROLE_1,
          // 管理员不能添加管理员
          disabled: this.role === globalVar.ADMIN_ROLE_1
        },
        {
          label: '客服（第三方合作单位）',
          value: globalVar.ADMIN_ROLE_2
        }
      ]

      return roleOptions
    },
    /**
     * 选择管理员时有地区的选择
     * @todo 管理员增加地区
     */
    adminRegionOptions () {
      // 此code码来源于cities.json
      return [
        {
          value: '430000',
          label: '湖南'
        },
        {
          value: '420000',
          label: '湖北'
        },
        {
          value: '360000',
          label: '江西'
        }
      ]
    }
  },
  filters: {
    formatRoleName (value, options) {
      const item = options.find(item => {
        return item.value === parseInt(value)
      })

      return item ? item.label : ''
    }
  },
  created () {
    if (this.id) {
      this.getAccountInfo()
    }
  },
  methods: {
    // 提交表单
    submitForm () {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          if (this.id) {
            this.editAccountInfo()
          } else {
            this.createAccount()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取账号详情
    getAccountInfo () {
      this.loading = true
      api.getAccountInfo(this.id).then((res) => {
        if (res.data.code === 0) {
          console.log('获取账号详情成功', res.data)
          this.formData = this.$clone(res.data.data)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 创建账号
    createAccount () {
      this.btnLoading = true
      api.createAccount({
        ...this.formData,
        password: SHA256(this.formData.password).toString()
      }).then((res) => {
        if (res.data.code === 0) {
          console.log('添加账号成功', res.data)
          this.$message.success('创建成功')
          this.$router.push('/account-manage/admin-manage')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },
    // 编辑账号
    editAccountInfo () {
      this.btnLoading = true
      api.editAccountInfo(this.id, {
        ...this.formData,
        password: this.formData.newPassword ? SHA256(this.formData.newPassword).toString() : ''
      }).then((res) => {
        if (res.data.code === 0) {
          console.log('编辑成功', res.data)
          this.$message.success('编辑成功')
          this.$router.push('/account-manage/admin-manage')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
